<template lang="pug">
#main-view
	LcapModal.learn-more-modal(
		v-model="showLearnModal",
		show-close-on-backdrop,
		close-on-backdrop-click,
		hide-footer
	)
		iframe.modal-video(
			src="https://www.youtube.com/embed/mqVCngfSNr0",
			title="YouTube video player",
			frameborder="0",
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
			allowfullscreen=""
		)
	ReuploadDataModal(v-model="showReuploadModal", :metric="currentMetric")
	GiveFeedback(v-model="showGiveFeedback")
	ConfirmModal(
		v-model="showConfirmDeleteModal",
		:message="confirmDeleteMessage",
		@confirm="deleteMetric"
	)
	SideNav(
		v-model="sideNavState",
		:priorities="priorities",
		:current-metric-key="currentMetricKey",
		:is-editing="isEditing",
		@select-metric="selectMetric",
		@edit="saveEdit",
		@logoClick="onLogoClick",
		@lcapInfo="showLearnModal = true"
	)
	#content(:class="navPaddingClasses")
		LcapNav.top-lcap-nav(
			:hide-logo="true",
			:show-home="true",
			@home="currentMetricKey = ''",
			@giveFeedback="showGiveFeedback = true"
		)
		//- .header
		//- 	template(v-if="user")
		//- 		p Logged in!
		//- 		button Home
		//- 		button Log Out
		//- 	template(v-else)
		//- 		p Not logged in!
		.unselected-container(v-if="!currentMetricKey")
			img.metric-drawing(
				src="../assets/img/select-metric-drawing.png",
				alt="Drawing of people with graphs"
			)
			.steps
				.step
					p.number 1
					p.title Select a Priority
					p.description From the side menu on the left
				.step
					p.number 2
					p.title Select a Metric
					p.description There are multiple metrics per priority
				.step
					p.number 3
					p.title View Insights
					p.description Interact with and filter graphs
			.divider
			p.question Not sure what you're looking at?
			button.btn.btn-primary(@click="showLearnModal = true") Learn About LCAP
		.selected-container(v-else)
			p.pretitle {{ currentPriority && currentPriority.name }}
			.title-and-actions
				h1.title {{ currentMetric && currentMetric.name }}
				.action-btns(v-if="isEditing")
					button.btn.primary-btn(@click="downloadMetric") Download Data
					button.btn.primary-btn(@click="showReuploadModal = true") Reupload Data
					button.delete-btn.btn.primary-btn(@click="showConfirmDeleteModal = true") Delete Metric
			LcapChart.chart-display(
				:metric="currentMetric",
				:metric-data="currentMetricData",
				:filters="filters",
				:is-editing="isEditing",
				@updateMetric="updateMetric"
			)
</template>

<script>
import Papa from "papaparse";
import {
	auth,
	onAuthStateChanged,
	database,
	ref,
	set,
	get,
	onValue,
} from "@/firebase";
import GiveFeedback from "@/components/GiveFeedback.vue";
import SideNav from "@/components/SideNav.vue";
import LcapNav from "@/components/LcapNav.vue";
import LcapModal from "@/components/LcapModal.vue";
import LcapChart from "@/components/LcapChart.vue";
import ReuploadDataModal from "@/components/ReuploadDataModal.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import BoldIcon from "@/components/icons/BoldIcon.vue";
import UnderlineIcon from "@/components/icons/UnderlineIcon.vue";
import ItalicIcon from "@/components/icons/ItalicIcon.vue";
import LinkIcon from "@/components/icons/LinkIcon.vue";
import CheckmarkIcon from "@/components/icons/CheckmarkIcon.vue";
import { localDistrictDataToPriorities } from "@/transforms";
import { deleteMetric } from "@/actions";

export default {
	name: "MainView",
	components: {
		GiveFeedback,
		SideNav,
		LcapNav,
		LcapModal,
		LcapChart,
		ReuploadDataModal,
		ConfirmModal,
		BoldIcon,
		UnderlineIcon,
		ItalicIcon,
		LinkIcon,
		CheckmarkIcon,
	},
	props: {
		isEditing: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			// Data that is bound to firebase auth state.
			// type: { district: 'pk', state: 'state_pushkey' }
			user: null,
			// Data also bound to firebase state, computed from userDistrict
			// and userState.
			userDistrictData: null,
			userStateData: null,

			currentMetricKey: "",
			currentMetricData: null,

			showReuploadModal: false,
			showConfirmDeleteModal: false,

			/*
			priorities: Array<{
				key: string;
				name: string;
				desc: string;
				metricsKey: string;
				metrics: Array<Metric>
			}>
			type Metric = {
				key: string;
				name: string;
				desc: Object;
			}
			*/

			filtersData: null,
			sideNavState: "initial",
			showLearnModal: false,
			showGiveFeedback: false,
		};
	},
	computed: {
		navPaddingClasses() {
			return {
				"nav-initial": this.sideNavState === "initial",
				"nav-closed": this.sideNavState === "closed",
				"nav-main-open": this.sideNavState === "main-open",
				"nav-sub-open": this.sideNavState === "sub-open",
			};
		},
		priorities() {
			if (!this.userDistrictData) return;
			return localDistrictDataToPriorities(this.userDistrictData);
		},
		currentPriority() {
			if (!this.priorities) return;
			return this.priorities.find((p) =>
				p.metrics.find((m) => m.key === this.currentMetricKey)
			);
		},
		currentMetric() {
			if (!this.currentPriority) return;
			return this.currentPriority.metrics.find(
				(m) => m.key === this.currentMetricKey
			);
		},
		filters() {
			if (!this.filtersData) return;
			return Object.entries(this.filtersData).map(([key, value]) => ({
				key,
				title: value.title,
				bars: value.bars
					? Object.entries(value.bars).map(([k, v]) => ({
							key: k,
							title: v.title,
					  })) // eslint-disable-line
					: [],
			}));
		},
		confirmDeleteMessage() {
			return `Are you sure you want to delete "${
				this.currentMetric && this.currentMetric.name
			}"?`;
		},
	},
	watch: {
		user(user) {
			if (!user) return;
			const { district } = user;

			// query for local data
			onValue(ref(database, `districts/${district}/local`), (snapshot) => {
				let userDistrictData = snapshot.val();
				if (userDistrictData === null) {
					userDistrictData = {
						properties: {},
						priorities: {},
						metrics: {},
					};
				}
				this.userDistrictData = userDistrictData;
			});

			// query for filter data
			onValue(
				ref(database, `settings/${district}/filters`),

				(snapshot) => {
					let filters = snapshot.val();
					this.filtersData = filters;
				}
			);

			// query for state data
			onValue(ref(database, `states/${this.user.state}`), (snapshot) => {
				let userStateData = snapshot.val();
				this.userStateData = userStateData;
			});
		},
	},
	created() {
		let dbInitialized = false;
		onAuthStateChanged(auth, (user) => {
			if (!user) {
				this.user = {
					district: "pk",
					state: "state_pushkey",
				};
				return;
			}
			if (dbInitialized) {
				return;
			}
			dbInitialized = true;
			// query for correct user district
			get(ref(database, `users/${user.uid}`)).then((infoSnapshot) => {
				this.user = infoSnapshot.val();
			});
		});
	},
	methods: {
		downloadMetric() {
			this.downloadChartData(
				this.currentMetricData.data,
				this.currentMetric.name
			);
		},
		downloadChartData(data, fileName = "download") {
			let csv = [
				[
					"Category",
					"colorValue",
					"timeSeriesLabel",
					"nVal",
					"dVal",
					"pnVal",
					"pdVal",
					"useRate",
					"curRate",
					"prevRate",
					"hidden",
					"schoolNumber",
				],
			];
			for (let i in data) {
				let schoolChart = data[i];
				let schoolCode = schoolChart.code,
					schoolData = schoolChart.d;
				for (let n in schoolData) {
					let column = schoolData[n];
					let columnLabel = column.label,
						columnData = column.d;
					for (let j in columnData) {
						let datum = columnData[j];
						csv.push([
							columnLabel, // Category
							Object.prototype.hasOwnProperty.call(datum, "color")
								? datum.color
								: "", // color
							datum.tf, // timeSeriesLabel
							// nVal
							Object.prototype.hasOwnProperty.call(datum, "num")
								? datum.num
								: "",
							// dVal
							Object.prototype.hasOwnProperty.call(datum, "den")
								? datum.den
								: "",
							"", // pnVal - deprecated
							"", // pdVal - deprecated
							Object.prototype.hasOwnProperty.call(datum, "rate") ? 1 : 0, // useRate
							// curRate
							Object.prototype.hasOwnProperty.call(datum, "rate")
								? datum.rate
								: "",
							"", // prevRate
							// hidden
							Object.prototype.hasOwnProperty.call(datum, "hidden")
								? datum.hidden
								: 0,
							schoolCode, // schoolNumber
						]);
					}
				}
			}
			this.buildAndDownloadCSV(csv, fileName);
		},
		buildAndDownloadCSV(csv, name = "download") {
			const csvStr = Papa.unparse(csv);
			const blob = new Blob([csvStr], { type: "text/csv" });

			let link = document.createElement("a");
			link.setAttribute("href", window.URL.createObjectURL(blob));
			link.setAttribute("download", name + ".csv");
			// firefox needs this
			document.body.appendChild(link);
			// artificial click, and remove
			link.click();
			window.URL.revokeObjectURL(link.href);
			link.remove();
		},
		async deleteMetric() {
			try {
				await deleteMetric(this.currentMetric);
				this.currentMetricKey = "";
			} catch (err) {
				console.log("Error deleting", err);
			}
		},
		onLogoClick() {
			this.currentMetricKey = "";
			if (this.sideNavState !== "initial") this.sideNavState = "closed";
		},
		async selectMetric(metric) {
			this.currentMetricKey = metric.key;
			onValue(
				ref(database, `charts/${this.user.district}/${metric.data}`),
				(snapshot) => {
					this.currentMetricData = snapshot.val();
				}
			);
		},
		async updateMetric({ key, name, desc }) {
			this.saveEdit({ type: "metric", key, property: "name", value: name });
			this.saveEdit({ type: "metric", key, property: "desc", value: desc });
		},
		saveEdit({ type, key, property, value }) {
			let editPath = "";
			switch (type) {
				case "metric": {
					const { metricsKey } = this.priorities.find((p) =>
						p.metrics.find((m) => m.key === key)
					);
					editPath = `metrics/${metricsKey}/${key}/${property}`;
					break;
				}
				case "priority":
					editPath = `priorities/${key}/${property}`;
					break;
				default:
					// Shouldn't happen
					return;
			}
			const basePath = `/districts/${this.user.district}/local/${editPath}`;
			return set(ref(database, basePath), value);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

#main-view {
	display: flex;
	flex-direction: column;
	background: $theme-light;
	width: 100vw;
	height: 100vh;
	overflow: auto;
}

.learn-more-modal {
	::v-deep .lcap-modal-content {
		border-radius: 0;
		max-width: unset;
		width: calc(100vw - 40px);
		height: calc(100vh - 200px);
		.container {
			height: 100%;
			overflow: hidden;
		}
		.close-btn {
			display: none;
		}
		@include md {
			// max-width: 1062px;
			// max-height: 661px;
		}
	}
	.modal-video {
		width: 100%;
		height: 100%;
	}
}

.reupload-modal-content {
	padding: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.edit-desc-modal {
	::v-deep {
		.lcap-modal-main {
			padding: 36px;
		}
		.lcap-modal-footer {
			padding: 16px;
		}
	}
	.modal-input {
		color: rgba(79, 79, 79, 0.7);
		outline-offset: 4px;
		outline-color: $color-lightblue;
		margin-top: 8px;
		display: block;
		width: 100%;
	}
	.focused {
		color: $color-lightblue;
	}
	.metric-name-label {
		display: block;
		color: rgba(79, 79, 79, 0.7);
		text-transform: uppercase;
		font-weight: 600;
	}
	.metric-name-input,
	.metric-name {
		font-size: 24px;
		font-weight: 600;
		// letter-spacing: -0.05em;
	}
	.desc-label {
		margin-top: 16px;
	}
	.desc-input ::v-deep .ProseMirror {
		outline-offset: 4px;
		outline-color: $color-lightblue;
	}
	.edit-btns {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
		gap: 12px;
		height: 100%;
		.edit-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 56px;
			height: 56px;
			border-radius: 16px;
			transition: all 0.1s ease;
			&.is-active {
				background: $color-lightblue;
				color: white;
				box-shadow: 0px 4px 16px rgba(24, 70, 136, 0.4);
			}
		}
		.modifiers {
			align-self: stretch;
			display: flex;
			justify-content: space-between;
		}
		.actions {
			border-top: 1px solid rgba(0, 0, 0, 0.1);
			display: flex;
			justify-content: center;
			gap: 24px;
			padding-top: 20px;
		}
		.cancel-btn,
		.save-btn {
			width: unset;
			height: unset;
			text-transform: uppercase;
			font-weight: 600;
			color: rgba(79, 79, 79, 1);
			.checkmark {
				margin-left: 8px;
			}
		}
		.divider {
			border: 1px solid rgba(0, 0, 0, 0.1);
		}
		.first-divider {
			display: none;
		}
		@include sm {
			flex-direction: row;
			gap: 20px;
			.first-divider {
				display: block;
			}
			.modifiers {
				flex: 1;
				align-items: center;
			}
			.actions {
				padding-top: 0;
				border-top: 0;
			}
		}
	}
}

#content {
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 20px;
	transition: margin 0.5s ease;

	&.nav-main-open,
	&.nav-sub-open {
		// Height of mobile nav when closed. Accomodates for the space that would
		// otherwise be taken up by the nav (prevents shifting of content upwards.)
		margin-top: 60px;
		@include md {
			margin-top: 0;
		}
	}

	.top-lcap-nav {
		margin-bottom: 16px;
	}

	.unselected-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.metric-drawing {
			margin-bottom: 48px;
			max-width: 100%;
			height: auto;
		}
		.steps {
			margin-bottom: 48px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 40px;
			.step {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 8px;
				.number {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 27px;
					height: 27px;
					border: 3px solid #4f4f4f;
					border-radius: 999px;
					font-weight: 700;
				}
				.title {
					font-weight: 500;
				}
				.description {
					font-size: 12px;
					color: rgba(79, 79, 79, 0.7);
				}
			}
		}
		.divider {
			width: 100%;
			max-width: 646px;
			border: 2px solid rgba(61, 61, 61, 0.2);
			margin-bottom: 70px;
		}
		.question {
			margin-bottom: 24px;
		}
	}

	.selected-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		.pretitle {
			text-transform: uppercase;
			color: rgba(79, 79, 79, 0.5);
			font-weight: 600;
		}
		.title-and-actions {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.title {
				margin: 8px 0;
			}
			.action-btns {
				display: flex;
				gap: 16px;
				margin-bottom: 8px;
				.delete-btn {
					background-color: $color-danger;
					margin-left: auto;
				}
			}
			@include md {
				flex-direction: row;
				align-items: center;
			}
		}
		.chart-display {
			flex: 1;
		}
	}
}

#chart-dropdowns {
	display: flex;
	flex-direction: column;
	@include md {
		flex-direction: row;
		align-items: center;
		gap: 16px;
		* {
			flex: 1;
			min-width: 0;
		}
	}
	.filter-wrapper,
	.selector-wrapper {
		display: flex;
		flex-direction: column;
	}
	label {
		color: #4f4f4f;
		font-size: 12px;
		padding-left: 15px;
		padding-bottom: 5px;
	}
	select {
		border: 2px solid rgba(41, 70, 97, 0.1);
		border-radius: 4px;
		color: $color-lightblue;
		font-size: 14px;
		font-weight: 500;
		padding: 8px;
		margin-bottom: 10px;
	}
}

#chart {
	background: white;
	border: 2px solid rgba(41, 70, 97, 0.1);
	border-radius: 4px;
	padding: 20px;
}
</style>
