export function setLink(editor) {
	const previousUrl = editor.getAttributes("link").href;
	const url = window.prompt("URL", previousUrl);
	// cancelled
	if (url === null) {
		return;
	}
	// empty
	if (url === "") {
		editor.chain().focus().extendMarkRange("link").unsetLink().run();
		return;
	}
	// update link
	editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
}

/**
 * Test whether a given string consists only of letters, numbers, and spaces.
 * @param {string} str
 * @returns {boolean}
 */
export function isAlphaNumeric(str) {
	const testRegex = /^[a-zA-Z0-9 ]+$/;
	return testRegex.test(str);
}

export function isEmail(str) {
	const re =
		// eslint-disable-next-line
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(str);
}

/**
 * Sorts time frame strings in descending order.
 *
 * @param {string[]} tfs
 * @returns {string[]}
 */
export function sortTimeframes(tfs) {
	/**
	 * There exists a number of different formats:
	 * 1. 4 digit year. 2021, 2019, 2015, etc. Easy to handle.
	 * 2. 2 to 4 digit year to a 2 to 4 digit year. 2019-2020, 2015-2016,
	 *    but also 2020-21, 2015-16, 17-18, etc.
	 * 3. Season + 4 digit year. Fall 2021, Spring 2019, etc.
	 */
	try {
		// Case (1)
		if (/^[0-9]{4}$/.test(tfs[0])) {
			return tfs
				.map((tf) => Number(tf))
				.sort()
				.reverse()
				.map((tf) => `${tf}`);
		}
		// Case (2)
		else if (/^[0-9]{2,4}-[0-9]{2,4}$/.test(tfs[0])) {
			return tfs
				.map((tf) => tf.split("-"))
				.map((tf) => (tf[0].length === 2 ? [`20${tf}`, tf[1]] : tf))
				.sort((a, b) => a[0] - b[0])
				.reverse()
				.map((tf) => `${tf[0]}-${tf[1]}`);
		}
		// Case (3)
		else if (/^(Fall|Spring) [0-9]{4}/.test(tfs[0])) {
			return (
				tfs
					.map((tf) => tf.split(" "))
					// Map Spring to 0, and Fall to 1
					.map((tf) => [tf[0] === "Spring" ? 0 : 1, tf[1]])
					.sort((a, b) => (a[1] - b[1]) * 10 + (a[0] - b[0]))
					.reverse()
					// Reverse Mapping back to Season, then join by space.
					.map((tf) => [tf[0] === 0 ? "Spring" : "Fall", tf[1]].join(" "))
			);
		}
		return tfs;
	} catch (err) {
		// Some random error occurred (due to bad input), so we don't want
		// to fail but just return the tfs as given.
		return tfs;
	}
}
